import Cookies from 'js-cookie'
import { LANG_ZH, LANG_EN, LANG_AUTO } from '@/constant/index'
import cryptoRandomString from 'crypto-random-string'

const refreshTokenKey  = 'ybRefreshToken'
const clientIdKey = 'cId'
const clientSecretKey = 'cs'

const avatarKey = 'avatar'
const uuidKey = 'uuid'


const acceptLanguageKey = 'acceptLanguage'
const localConferenceNoKey = 'localConferenceNoKey'
const displayNameKey = 'displayNameKey'

const conferenceTokenKey = 'X-Conference-Token'
const channelTokenKey = 'X-Channel-Token'

const localUserInfoKey = "localUserInfoKey"
const userName = 'userName'
const userId = 'userId'
const mopanToken = 'mopanToken'

const appidKey = 'appid'


// 获取会议服务token
export function getConferenceToken () {
    return localStorage.getItem(conferenceTokenKey)
}
// 设置会议服务token
export function setConferenceToken (token) {
    localStorage.setItem(conferenceTokenKey, token)
}
// 移除会议服务token
export function removeConferenceToken () {
    localStorage.removeItem(conferenceTokenKey)
}


// 获取IM token
export function getChannelToken () {
    return localStorage.getItem(channelTokenKey)
}
// 设置IM token
export function setChannelToken (token) {
    localStorage.setItem(channelTokenKey, token)
}
// 移除IM token
export function removeChannelToken () {
    localStorage.removeItem(channelTokenKey)
}


/**
 * 存储会议相关信息
 * @param {*} meetToken 
 * @param {*} imToken 
 */
export function setMeetInfo(meetToken, imToken) {
    setConferenceToken(meetToken)
    setChannelToken(imToken)
}

/**
 * 清空会议相关信息
 */
export function removeMeetInfo() {
    removeConferenceToken()
    removeChannelToken()
}



/**
 * 存储用户相关信息
 */
export function setLocalUserInfo(data) {
    const localData = getLocalUserInfo()

    localStorage.setItem(
      localUserInfoKey,
      JSON.stringify({
        ...localData,
        ...data,
      })
    )
}
/**
 * 获取用户相关信息
 */
export function getLocalUserInfo() {
    const data = localStorage.getItem(localUserInfoKey);

    return data ? JSON.parse(data) : {};
}
/**
 * 清空用户相关信息
 */
export function removeLocalUserInfo() {
    localStorage.removeItem(localUserInfoKey)
}


// refreshToken
export function getRefreshToken() {
    return Cookies.get(refreshTokenKey)
}

export function setRefreshToken(token) {
    Cookies.set(refreshTokenKey, token)
}

export function removeRefreshToken() {
    Cookies.remove(refreshTokenKey)
}


// client_id
export function getClientId() {
    return Cookies.get(clientIdKey)
}

export function setClientId(val) {
    Cookies.set(clientIdKey, val)
}

export function removeClientId() {
    Cookies.remove(clientIdKey)
}

// client_secret
export function getClientSecret() {
    return Cookies.get(clientSecretKey)
}

export function setClientSecret(val) {
    Cookies.set(clientSecretKey, val)
}

export function removeClientSecret() {
    Cookies.remove(clientSecretKey)
}





// 头像
export function getAvatar () {
    return Cookies.get(avatarKey)
}
export function setAvatar (avatar) {
    return Cookies.set(avatarKey, avatar)
}
export function removeAvatar () {
    return Cookies.remove(avatarKey)
}


// 设置uuid
export function setUuid (token) {
    return Cookies.set(uuidKey, token)
}
export function getUuid () {
    let uuid = Cookies.get(uuidKey)
    if (!uuid) {
      uuid = cryptoRandomString({length: 10})
      setUuid(uuid)
    }
    return uuid
}
// 移除uuid
export function removeUuid () {
    return Cookies.remove(uuidKey)
}

// 获取语言值
export function getAcceptLanguage() {
    const localLang = getLocalLanguage()
    const sysLang = navigator.language === LANG_ZH ? LANG_ZH: LANG_EN

    if (!localLang) { // 不存在，存储自动选项
        setLocalLanguage(LANG_AUTO)
        return sysLang
    } else { // 存在
        if (localLang === LANG_AUTO) { // 自动
            return sysLang
        } else {
            return localLang
        }
    }
}
export function isZhLang() {
    return getAcceptLanguage() === LANG_ZH
}
export function getLocalLanguage() {
    return localStorage.getItem(acceptLanguageKey)
}
export function setLocalLanguage(val) {
    localStorage.setItem(acceptLanguageKey, val)
}
export function removeLocalLanguage() {
    localStorage.removeItem(acceptLanguageKey)
}


// 存储appid：异常退会后取不到appid的问题
export function getAppid () {
    return localStorage.getItem(appidKey)
}
export function setAppid (val) {
    localStorage.setItem(appidKey, val)
}
export function removeAppid () {
    localStorage.removeItem(appidKey)
}

// 会议号
export function getLocalConferenceNo () {
    return localStorage.getItem(localConferenceNoKey)
}
export function setLocalConferenceNo (val) {
    localStorage.setItem(localConferenceNoKey, val)
}
export function removeLocalConferenceNo () {
    localStorage.removeItem(localConferenceNoKey)
}


// 会议展示名
export function getDisplayName () {
    return localStorage.getItem(displayNameKey)
}
export function setDisplayName (val) {
    localStorage.setItem(displayNameKey, val)
}
export function removeDisplayName () {
    localStorage.removeItem(displayNameKey)
}
// 加入会议名称
export function getUserName () {
    return localStorage.getItem(userName)
}
export function setUserName (val) {
    localStorage.setItem(userName, val)
}
export function removeUserName  () {
    localStorage.removeItem(userName)
}
// 加入会议id
export function getUserId () {
    return localStorage.getItem(userId)
}
export function setUserId (val) {
    localStorage.setItem(userId, val)
}
export function removeUserId  () {
    localStorage.removeItem(userId)
}
// 工作台token

export function getMeetToken () {
    return localStorage.getItem(mopanToken)
}
export function setMeetToken (val) {
    localStorage.setItem(mopanToken, val)
}
export function removeMeetToken () {
    localStorage.removeItem(mopanToken)
}



// reset
export function removeUserInfo() {
    removeRefreshToken()//refreshToken
    //removeClientId()//client_id
    //removeClientSecret()//client_secret
    removeAvatar()//头像
    removeLocalUserInfo()//清空用户相关信息
    removeMeetInfo()//清空会议相关信息
    removeUuid()//移除uuid
    removeMeetToken()
    removeUserName ()
    removeUserId()
}