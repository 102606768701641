<template>
  <div id="app">
    <error-handler></error-handler>
    <network-error></network-error>
    <router-view ref="router-view" v-if="isRouterAlive"/>
  </div>
</template>
<script>
import cryptoRandomString from 'crypto-random-string'
import { setUuid } from "@/utils/auth"
import ErrorHandler from './components/ErrorHandler.vue';
import NetworkError from './components/notify/NetworkError.vue';


export default {
  components: { ErrorHandler, NetworkError },

  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  },

  created() {
    setUuid(cryptoRandomString({length: 10}))
  },

  mounted() {
  }
};
</script>

<style lang="less">
html, body{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#app{
  margin: 0 auto;
  width: 750px;
  height: 100%;
}

div {
  box-sizing: border-box;
}

//全局 js调用弹窗的默认样式调整
.default-dialog .van-dialog__header{
  padding-left: 50px;
  padding-right: 50px;
}
.van-overlay {
   overflow: hidden !important;
 }

 .toastBottom{
  top:80% !important;
}
</style>
<!-- .isOpenWenjuanAskDialog{
  z-index: 9999 !important;
} -->