import router from "./router"
import { GetQueryString } from '@/utils/getQueryString'

//需要登录验证跳转登录页
const isLoginPageList = ['home', 'detail', 'appointment', 'fast']
//需要登录验证跳转主页
const isHomePageList = ['index']

router.beforeEach((to, from, next) => {


  //如果需要重定向 如果url 包含回调的地址 立即跳转
  if(location.search.indexOf("redirect_url") > -1) {
    let redirectUrl = GetQueryString('redirect_url')
    console.error('redirectUrl', redirectUrl)
    if(redirectUrl) {
      // const name = redirectUrl.substring(redirectUrl.lastIndexOf("\/") + 1, redirectUrl.indexOf("?"))
      redirectUrl = decodeURIComponent(redirectUrl)
      //console.error(name,query)
      location.href =  redirectUrl
      //next({ name, query, replace: true })
      return
    }
  }

  //入会成功 记忆一下入会前路由信息
  if (to.name === "meeting") {
    window.localStorage.setItem('joinBeforeRoute', JSON.stringify({
      name: from.name,
      query: from.query
    }))
  }

  const appHide = localStorage.getItem('appHide'); // 监听 锁屏

  if( appHide && isHomePageList.indexOf(to.name) > -1) { //需要跳到主页的
    const joinBeforeRoute = JSON.parse(window.localStorage.getItem('joinBeforeRoute') || '{}')

    console.error('joinBeforeRoute', joinBeforeRoute)
    next({ ...joinBeforeRoute, replace: true })
    return
  }

  else { // 不需要登录的界面
    next({})
  }

})
