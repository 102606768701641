// 定义项目配置文件
export default {
    install(Vue, options) {
        Vue.prototype.$configs = {
            userId: '', // 用户Id
            roomId: '', // 房间Id
            peerId: '', // 会议服务器用户Id
            userName: '', // 用户名
            avatarUrl: '', // 头像
            conferenceNo: '', // 会议ID

        }
    }
}