import Vue from "vue";
import Router from "vue-router";

const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

const routerReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return routerReplace.call(this, location).catch((error) => error);
};

Vue.use(Router);

const routes = [
 
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/join/index.vue"),
  },
  {
    path: "/join",
    name: "join",
    component: () => import("@/views/join/index.vue"),
  },
  {
    path: "/endJoin",
    name: "endJoin",
    component: () => import("@/views/join/endJoin.vue"),
  },
  {
    path: "/guide",
    name: "guide",
    component: () => import("@/views/guide/index.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home/index.vue"),
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/detail/index.vue')
  },
  {
    path: "/meeting/:userID",
    name: "meeting",
    component: () => import("@/views/meeting/Meeting.vue"),
  },
];

const router = new Router({
  mode: "history", // hash
  routes,
});

export default router;
