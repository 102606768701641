export default {
  common: {
    title: '会议',
    deviceAuthErrorTitle: '禁止麦克风或摄像头权限后将无法入会',
    deviceAuthErrorMsg: '需要手动在浏览器设置中开启对应权限才能正常使用会议',
    mobileTips: '暂不支持手机/iPad/pad浏览器入会，请使用MAC / Windows电脑浏览器入会～',
    back: '返回'
  },
  manager: { // 会前
    langeData: {
      zh: "简体中文",
      en: "英文"
    }
  },
  home: {
    title: '会议',
    joinMeeting: '小程序入会',
    signIn: '登录',
    version: '版本号：',
    titleTips: '下载会议客户端',
    quickJoin: '快速轻松加入会议',
    back: '< 返回',
    personMeet: '个人中心',
    index: '主页',
    meetRecord: '会议记录'
  },
  login: {
    successPwd:'请输入正确的密码',
    clearLog:'清空记录',
    setPwd:'设置新密码',
    codeFail:'验证码不正确,请检查后重试',
    next:'下一步',
    backIndex:'返回登录页，即可',
    pwdSuccess:'恭喜您，密码设置完成',
    newLogin:'注册',
    changeMobile:'您的手机号码已被注册，请更换手机号码进行',
   loginFail: '很抱歉，注册失败',
    emailAccount:"邮箱账号",
    nowLogin:'立即登录',
    gxsuccess:'恭喜您，注册成功',
    newTips: '我们推荐使用Chrome浏览器，其他可用浏览器：edge、360、360极速、Safari',
    newTitle: '当前浏览器暂不支持使用',
    newDown: '下载新版Chrome',
    versionTips: '请先将该浏览器升级到最新版本再用',
    versionTitle: '当前浏览器版本暂不支持使用',
    downClient: '下载客户端',
    meetNo: '请输入4-6位会议密码',
    onlyNo: '请输入4-6位主持人密码',
    week: '周',
    second: '秒',
    openMicrophone: '开启麦克风',
    openSpeaker: '开启扬声器',
    openCamera: '开启摄像头',
    login: '加入',
    numberTips: '会议号（9-10位数字）',
    tipsName: '会议中显示的名字',
    enterprise: '企业登录',
    self: '个人登录',
    mobilePhone: '验证码登录',
    pws: '密码登录',
    mailbox: '邮箱',
    phone: '手机号',
    inputPwsd: '请输入密码',
    inputCode: '请输入验证码',
    findPwsd: '忘记密码',
    noAccount: '没有账号？立即',
    register: '注册新用户',
    know: '知道了',
    try: '手机号码格式不正确，请检查后重试',
    failEmail: '企业邮箱格式不正确',
    faildCode: '验证码发送失败',
    failTextCode: '验证码格式不正确',
    successCode: '验证码已发送',
    resend: ' s后重新发送',
    getCode: '获取验证码',
    loginFailed: '登录失败',
    code: '验证码',
    password: '密码',
    enterpriseMailbox: '企业邮箱',
    notRegistered: '尚未注册',
    complete: '完成',
    surePwd: '请输入新密码',
    netFail: '网络连接异常，请检查后重试',
    tips: '温馨提示',
    newPws: '新密码',
    surePws: '确认密码',
    newUser: '新用户注册',
    phoneNumber: '手机号码',
    pwsTips: '密码(由8-16位大小写字母、数字及字符组成)',
    inputSurePws: '请再次输入密码',
    userName: '昵称',
    onLogin: '立即注册',
    okFine: '同意并遵守',
    termService: '《服务条款》',
    pleasePws: '请输入会议密码',
    sure: '确定',
    cancel: '取消',
    save: '保存',
    meetingPwd: '4-6位会议密码',
    leaveMeet: '离开会议',
    startTime: '开始时间:',
    ifMain: '如果您是主持人, 请登录并开启会议',
    meetNumber: '会议号：',
    meetTheme: '会议主题：',
    end: '结束',
    noEnd: '不结束',
    meetPwd: '入会密码',
    pleaseTwo: '会议密码输入错误,请重新输入',
    failMeet: '加入会议失败',
    goHands: '手动跳转页面>>',
    resetPwd: '密码重置成功!',
    ybGo: '您的医百通行证已注册成功!',
    dateTime: '日期与时间 (',
    bjTime: '北京时间',
    setMeetPwd: '设置会议密码',
    setMainPwd: '设置主持人密码',
    joinMeetok: '允许成员在主持人进入会前加入会议',
    mute: '成员入会议时静音',
    showTips: '成员进入时播放提示音',
    relieve: '允许成员自我解除静音',
    zerominute: '0分钟',
    shwminute: '15分钟',
    ssminute: '30分钟',
    swminute: '45分钟',
    whomeet: '的预约会议',
    bookMeet: '预约会议',
    add: '点击添加',
    editMeet: '编辑预约会议',
    hasMeet: '已有议程',
    hour: '小时',
    meetName: '会议名称',
    meetPsd: '会议密码：',
    meetRequire: '会议密码必须是4-6位数字',
    mainPwd: '主持人密码：',
    meetPsdNo:'会议密码不能为空',
    mainPwdNo:'主持人密码不能为空',
    mainPwd2:'主持人密码',
    mainRequire: '主持人密码必须是4-6位数字',
    timeRureire: '开始时间不能早于当前时间',
    duration: '持续时间',
    setMeet: '会议设置：',
    indexSet: '设置',
    setMeets: '会议设置：',
    agenda: '会议议程',
    selfNumber: '个人会议号',
    more: '更多',
    edition: '企业版',
    ybkj: '医百科技有限公司',
    free: '免费版',
    callService: '升级请联系客服,400-114-9100',
    singleTime: '单次会议时长',
    mintue: '分钟',
    mintues: '分钟',
    unlimited: '不限',
    maxNumber: '会议人数上限',
    system: '会议管理系统',
    modify: '修改',
    goto: "前往",
    logOut: '退出登录',
    tokenTime: 'token过期, 请重新登录',
    left: '左上角',
    right: '右上角',
  },
  seeting: {
    keepOnfrontTitle: '会议期间请保持小程序运行在前台否则会议将会异常退出',
    loading:'加载中...',
    clickRelod:'请求失败，点击重新加载',
    noMore:'没有更多了',
    version: '版本',
    agreement:'医百用户协议',
    telephone:'联系电话',
    password:'密码',
    showTime: '显示参会时长',
    showYourTime: '在会中显示您的入会时长',
    mailbox: '邮箱',
    phone: '手机号',
    headPortrait: '头像',
    currency: '通用',
    succCopy: '复制成功',
    inviteInfo: '邀请信息',
    uploadlog: '上传日志',
    help: '帮助中心',
    service: '客服',
    about:'关于',
    onlineService: '在线客服',
    Sun: '星期日',
    Mon: '星期一',
    Tues: '星期二',
    Wed: '星期三',
    Thur: '星期四',
    Fri: '星期五',
    Sat: '星期六',
    year: '年',
    myMonth: '月',
    myToday: '日',
    moring: '上午 ',
    afternoon: "下午 ",
    copyINfo: '复制邀请',
    edit: '编辑',
    delete: '删除',
    goMeet: '开始会议',
    meetTips: '今天没有即将召开的会议',
    lookMeet: '查看今天即将召开的会议',
    newMeet: '新会议',
    oldMeet: '历史会议',
    mySelfMeet: '我的个人会议号',
    no: '暂无',
    yy: '预约',
    ls: '历史',
    meet: '会议',
    meetEnd: '会议已结束',
    startMeet: '会议开始时间：',
    endMeet: '会议结束时间：',
    copySuccess: '邀请信息已复制成功',
    ifEnd: '是否结束当前正在进行中的会议',
    noDelete: '删除后暂不可恢复，且删除后不再支持其他成员入会',
    deleteMeet: '删除会议',
    meetList: '参会方人员列表(',
    Irrecoverable: '删除后暂不可恢复',
    userName: '用户名称',
    editSuess: '用户名称修改成功',
    oldPwd: '原登录密码',
    diffPwd: '两次输入密码不一致',
    editPwdSuccess: '用户密码修改成功',
    editMeet: '修改会议成功',
    useSelf: '使用个人会议号',
    now: '现在 ',
    countMeet: ' 场会议',
    afterStart: ' 分钟后开始 ',
    today: '今天',
    immediately: '立即',
    imMeet: '即将召开',
    haveHand: '进行中',
    Cancelled: '已取消',
    personMeet: '的个人会议室',
    fastMeet: '的快速会议',
    failMeet: '召开会议失败',
    meetFail: '预约会议失败',
    setMeet: '个人会议号设置',
    firstPerson: '发起人：',
    joinPerson: '参会成员：',
    excelPerson: '导出',
    joinAgain: '重新入会',
    changeName: '修改名称',
    inputUserName: '请输入用户名称',
    changePwd: '修改密码',
    inputOldPwd: '请输入原登录密码',
    inputNewPwd: '请输入新登录密码',
    inputAgainPwd: '请再次输入新密码',
    thinkAgain: '再想想'
  },
  meeting: { // 会中
    chatList:'聊天',
    moreInfo:'查看更多消息 >',
    infoTitle: '会议信息',
    enterError: '进入会议失败，请尝试重新入会',
    operationFailed: '操作失败，请重试',
    audioNotPermission: '暂无麦克风权限，请在浏览器获取该权限后重试',
    videoNotPermission: '暂无摄像头权限，请在浏览器获取该权限后重试',
    audioOccupy: '麦克风设备被占用，请尝试退出其他软件后重试',
    videoOccupy: '摄像头设备被占用，请尝试退出其他软件后重试',
    Sharing: '屏幕共享',
    allShare: '允许会议共享您的屏幕',
    openShareDeniedHelp: '打开系统偏好设置>安全性与隐私>隐私>屏幕录制，勾选会议，以授予访问权限',
    Frame: '帧',
    setMeeting: '会议设置',
    frameRate: '帧率',
    Resolution: '分辨率',
    bitrate: '码率',
    nets: '网络',
    delay: "延迟",
    bandwidth: '带宽',
    shouVideo: '隐藏视频',
    lookAllVideo: '查看全部成员视频',
    selfVideo: '查看本人视频',
    lookSpeakVideo: '查看发言者视频',
    lookShare: '查看共享画面',
    meetID: '会议号',
    host: '主持人',
    invite: '邀请链接',
    invitation: '邀请',
    invitationMethod: '选择邀请方式',
    attendee: '参会者ID',
    copy: '复制',
    encrypted: '会议已加密保护',
    network_good: '网络连接正常',
    net_wrong: '网络连接中断，正在重连',
    new_poor: '网络连接较差',
    net_ver: '网络连接极差',
    network_look: '点击检查网络',
    latency: '延时',
    packet: '丢包率',
    recording: '录制中',
    help: '帮助',
    log: '上传日志',
    kf: '客服',
    grid: '宫格视图',
    speaker: '演讲者视图',
    grid9: '一屏9等分',
    grid25: '一屏25等分',
    speakerTop: '顶部成员列表',
    speakerRight: '右侧成员列表',
    speakerFull: '全屏-演讲者',
    noChangeLayout: '观看共享内容期间无法切换到宫格视图',
    audioMute: '静音',
    audioUnMute: '解除静音',
    searchPerson: '搜索成员',
    searchMicrophonePerson:'搜索打开麦克风的成员',
    filter:'筛选',
    acquiescence:'默认',
    microphoneOn:'麦克风开启',
    changeName: '改名',
    setMain: '设为主持人',
    goHost: '设为联席主持人',
    removeHost: '撤销联席主持人',
    stopVideo: '停止视频',
    stopShare: '停止该成员的共享',
    putHands: '手放下',
    removeMeet: '移出会议',
    noPerson: '未找到相关成员',
    allMute: '全体静音',
    silenceall: '解除全体静音',
    allStopHands: '全体停止举手',
    getMain: '收回主持人',
    handsUp: '举手',
    becomeHost: '成为主持人',
    lockMeet: '锁定会议',
    lockShare: '锁定共享',
    lockRecord: '锁定录制',
    noVoiceMeet: '成员入会时静音',
    tipsVoice: '成员进入时播放提示音',
    confirmWill: '确定将',
    moveMeet: '移出会议吗？',
    outMeet: '移出会议',
    newVoice: '所有以及新加入的成员都被静音',
    inputPwd: '输入主持人密码',
    endShare: '停止共享',
    surShare: '确定停止正在进行的共享',
    normalUser: '普通用户',
    nohost: '联席主持人',
    Withdrawn: '已收回',
    moderator: '联席主持人权限',
    hostFail: '撤销联席主持人失败001',
    beHost: '已成为联席主持人',
    goFail: '设为联席主持人失败001',
    removeSuccess: '移出成员成功',
    removeMeetFail: '移出成员失败（60026)',
    noVoiceFail: '您无法自我解除静音，请向主持人申请解除静音权限',
    pleaceVoice: '已请求全体成员开麦',
    me: '我',
    myChat: '我',
    haveTime: '会议剩余时间：',
    agenda: '议程',
    instability: '网络不稳定正在连接…',
    pleacenoVoice: '主持人请求解除您的静音',
    keepQuiet: '保持静音',
    failMeet: '进入会议失败',
    pleaseOpen: '请打开麦克风/摄像头权限，再刷新浏览器，并重新尝试入会',
    abnormality: '网络异常',
    exception: '网络异常，请检查网络设置，或尝试重新入会',
    againShare: '您当前的共享已中断，请重新尝试共享',
    goMeeting: '正在进入会场中',
    inspectCamera: '启动视频失败，请在设置中选择其他摄像头',
    inspectVoice: '启动麦克风失败，请在设置中选择其他麦克风',
    netWorkBad: '网络异常，请检查网络设置',
    netWorkTimeout: '抱歉，网络请求超时。请重试',
    getOut: '您已被主持人移出会议',
    overTimeMeet: '主持人已结束会议',
    closeMeet: '本次会议已结束',
    best: '最好',
    good: '好',
    commonly: '一般',
    noGood: '差',
    noBest: '很差', 
    notAvailable: '不可用',
    becomeGoHost: '您已成为联席主持人',
    backModerator:'主持人已收回您的联席主持人权限',
    loging:'正在上传日志，请稍后',
    logSuccess:'上传日志成功',
    logFail:'上传日志失败，请重试',
    noLog:'上传日志失败，暂无日志',
    becomeMain:'您已成为主持人',
    mainNoVoice:'您已被主持人静音',
    allNoVoice: '所有人已被主持人静音',
    mainStopShare: '主持人已停止您的共享',
    closeVideoByHost: '你已被主持人关闭视频',
    giveYou: '已经将主持人权限转交给您',
    noMembership: "您已在其它设备加入会议,当前设备被移出会议",
    lijiEnd: "您的会议将在",
    inMinutes: '分钟后结束',
    lockMeeting: '已锁定，新成员无法加入',
    okMeet: '已解锁，新成员将可以加入',
    selectMicrophone: '选择麦克风',
    closeMicrophone: '关闭麦克风',
    openMicrophone: '打开麦克风',
    selectSpeakers: '选择扬声器',
    closeSpeakers: '关闭扬声器',
    openSpeakers: '打开扬声器',
    openVideo: '开启视频',
    chooseVideo: '选择摄像头',
    closeVideo: '关闭摄像头',
    onepCamera: '打开摄像头',
    share: '共享',
    sharingRights: '共享权限',
    onlyMain: '仅主持人共享',
    allPeoples: '所有人可共享',
    stopThisShare: '停止当前的共享',
    isStopShare: '是否停止当前正在进行的共享',
    stopSharing: '当前有人正在共享，是否先停止当前正在进行的共享？',
    noStop: '不停止',
    isStop: '停 止',
    youShare: '您正在共享',
    hostShare: '主持人正在共享',
    haveShare: '当前有人正在共享',
    getShareIng: '请向主持人申请共享权限',
    youAreShare: '您正在共享中',
    shareTop: '共享暂停',
    lookWhoShare: '你正在查看',
    shares: '的共享',
    lookBIg: '观看共享画面大小',
    windowOk: '适应窗口',
    okdx: '实际大小',
    bfb: '100%(实际大小)',
    unlockScreen: '取消锁定',
    lockScreen: '锁定画面',
    screenUnlock: '画面已解锁',
    unLocking: '画面已锁定，点击左上角取消锁定',
    startRecording: '开始录制',
    RecordingRights: '录制权限',
    hostRecordingOnly: '仅主持人录制',
    allRecord: '所有人可录制',
    getRecord: '请向主持人申请录制权限',
    recordCoding: '启用录制功能请下载安装Windows或MacOS客户端',
    handsIng: '正在举手',
    member: '成员',
    ManagingMembers: '管理成员',
    ren: '人',
    geren: '个人',
    chat: '聊天',
    chatList: '聊天列表',
    eitherMe: '我',
    toEveryone: '发给所有人',
    sendTo: '发送至',
    PrivateChat: '(私聊)',
    aite: '有人@你，点击查看>>',
    toSend: '发送给:',
    allPeople: '所有人',
    inputChat: '请输入聊天内容',
    send: '发送',
    goRoom: '您被踢出了房间',
    imgTipsOne: '上传图片只能是 JPG或png 格式!',
    imgTipsTwo: '上传图片大小不能超过 2MB!',
    wordLimt: '聊天输入内容最多不能超过300字',
    words: '聊天内容',
    endMeetings: '结束会议',
    leaveMeetings: '离开会议',
    endMeetingsTip: '请在离开会议前指定新的主持人',
    leaveMeetingsTip: '您确定离开会议吗？',
    countDown: '倒计时',
    timerDown: '倒计时',
    timedOut: '已超时',
    countDownTime: '倒计时分钟数:',
    displayLocation: '显示位置:',
    littlePeople: '会场小于2人，无法切换到宫格视图',
    IMPlaceholder: '我要说点什么...',
    ok: '确认',
    waitHost: '请等待主持人加入会议',
    participantRights: '参会者权限',
  },
  setUp: {
    register: '您的医百通行证已注册成功!',
    aweek: '一周',
    tweek: '二周',
    thweek: '三周',
    fweek: '四周',
    realTime: '实时',
    oneDay: '1天',
    sevenDay: '7天',
    thirdyDay: '30天',
    nineDay: '90天',
    season: '季',
    sDay: '近三天',
    weekDay: '近一周',
    oneMonth: '近一月',
    yMonth: '一月',
    tMontn: '二月',
    thrMonth: '三月',
    fMonth: '四月',
    fivMonth: '五月',
    sixMonth: '六月',
    sevenMonth: '七月',
    eigMonth: '八月',
    nineMonth: '九月',
    shiMonth: '十月',
    eleMonth: '十一月',
    tlvMonth: '十二月',
    placTime: '请选择日期时间',
    reset: '重置',
    set: '设置',
    close: '关闭',
    tuiTest: '退出检测',
    tobeTested: '待检测',
    seeting: '设置',
    second: '帧/秒',
    language: '语言设置',
    change: '请选择',
    networkRight: '网络权限',
    netDetection: '网络检测',
    pleaseDec: '请点击开始检测',
    start: '开始检测',
    detectionServer: '正在检测服务器检测情况',
    realmName: '解析域名耗时',
    networkStatus: '网络状况',
    netOk: '完成网络检测，当前网络状况',
    againDec: '重新检测',
    Detected: '检测出',
    netConnection: '网络连接情况',
    serverConnection: '服务器连接情况',
    mediaConnection: '媒体服务器连接情况',
    inNet: '网络检测中',
    someQuestion: '个异常问题，请检查网络连接',
    ok: '正常',
    overTime: '超时',
    connectionTime: '连接耗时',
    submitFeedback: '提交反馈',
    thanks: '感谢您提出的所有想法，建议和使用问题反馈',
    contactInfo: '联系方式',
    call: '非法内容举报电话：991 039 485',
    cameraOK: '若能看到摄像头画面，则说明摄像头正常',
    camera: '摄像头',
    myVideo: '我的视频',
    microphone: '麦克风',
    voice: '音量',
    microphoneOk: '对麦克风讲话，能看到音量波动则麦克风正常',
    speaker: '扬声器',
    stopDetection: '停止检测',
    testLoudspeaker: '检测扬声器',
    voiceOk: '正在播放声音...',
    normal: '常规',
    audio: '音频',
    video: '视频',
    startMeet: '开始或加入会议时进入全屏',
    fullScreen: '当与会者共享屏幕进入全屏',
    showTime: '显示我的参会时长',
    limit: '将我的屏幕共享限制为',
    useVideo: '使用视频镜像',
    highVideo: '高清画质',
    useHardware: '使用硬件加速',
    detecting: '正在检测',
    abnormal: '异常',
    poor: '较差',
    range: '极差',
    mobilePhone: '手机号/邮箱',
    stopVoice: '点击停止测试，即可停止播放当前测试音频',
    addSpeack: '点击测试扬声器，确保您可以听到他人声音',
    lookIng: '预览中',
    closeCamrea: '摄像头已关闭',
  },
  reg: {
    //新增文字提示
    emptyMeetName: '会议名字不能为空',
    huiyiPwd: '请检测密码长度是否是4-6位的正整数',
    pwdType:'密码由8-16位大小写字母、数字以及字符组成',
    countTime: '请输入1～1000的整数',
    nameEmpty: '用户名称不得为空',
    emptyPwd: '请检测输入密码是否为空',
    lengthPwd: '请检测输入密码长度是否在8～16位',
    somePwd: '请检测新密码两次输入是否一致',
    oldNewSome:'旧密码和新密码一致',
    // 原有正则校验
    regEmpty: "不能为空",
    regMaxLength: "最大长度不能大于",
    regMaxLengthByArray: "单个词最大长度不能大于",
    regMaxLengthByArray2: "单个词最大长度不能大于",
    regSpecial: "有非法字符",
    regSpecial2: "有非法字符",
    regDate: "日期格式不正确",
    regDateTime: "日期格式不正确",
    regDateTimeNoSecond: "日期格式不正确" + "(不带秒)",
    regLimitDate: "日期只能限制在{0}天内",
    regCompareDate: "开始时间不能大于结束时间",
    regCompareNumber: "开始数字不能大于结束数字",
    regCompareNumber2: "开始数字不能大于结束数字",
    regMobile: "格式不正确",
    regEmail: "格式不正确",
    regNumber: "只能填写数字",
    regNumber2: "只能填写正负整数",
    regNumber4: "只能填写正数",
    regNumber5: "只能填写正整数",
    regNumber7: "只能填写正整数",//只能填写正整数和包含0的数字
    regBetwwenLength: "长度必须在{0}到{1}之间",
    regBetwwenLength2: "长度必须在{0}到{1}之间",
    regNumberBetwwenLength: "范围必须在{0}到{1}之间,包含 ({0}或{1})",
    regUrl: "格式不正确",
    regIP: "格式不正确",
    regNorm: "只能输入数字、字母、下划线、小数点",
    regNorm2: "只能输入数字、字母、横杠",
    regNorm3: "只能输入数字、字母",
    regNorm4: "只能输入中文、字母",
    regNorm5: "只能输入数字、字母、中文",
    regNorm6: "只能输入数字、字母、(、)、-",
    regNorm7: "只能输入数字、字母、下划线、-",
    regNorm8: "只能输入数字、英文逗号",
    regCommon: "只能输入数字、字母、中文、下划线",
    regCommonWords: "只能输入数字、字母、中文、韩文、日文、下划线",
    regCommonPwd: "密码输入错误",
    regKorean: "只能输入数字、字母、中文、韩文、下划线",
    regFilter: "有不良信息",
    regKeyword: "不能是关键词",
    regKeyword2: "不是是关键词",
    regStartsWithNoNumber: "不能以数字开头",
    regNoLetter: "不能全是字母",
    regNoNumber: "不能全是数字",
    regSelect: "请选择",
    regCheckbox: "请选择",
    regEquals: "不一致",
    regNotEquals: "不能相等",
  }
}