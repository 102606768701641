<template>
    <div>
      <div class="network-bad-notify" v-if="isShowNetworkError">
       <img src="@/assets/images/icon/icon_error.png"/>
       <span>{{ msg || $t('meeting.netWorkBad')}}</span>
       <my-icon fontSize="28" v-if="isShowMore" iconName="icon-jiantou_xiajiantou_32_hui1" class="arrow" @click="gotoNetworkSet()" />
      </div>
      <div class="network-set" v-if="showNetworkSet">
        <van-nav-bar left-arrow @click-left="onBackHandle"/>
        <div class="text-content">
          <p class="title">未能连接到互联网</p>
          <p>您的设备未启用移动网络或Wi-Fi网络</p>
          <p class="title">如需要连接到互联网，可以参照以下方法:</p>
          <p>在设备的“设置” - “Wi-Fi网络” 设置面板中选择一个可用的Wi-Fi热点接入。</p>
          <p class="p-spacing">在设备的 “设置” - “网络” 设置面板中启用蜂窝数据(启用后运营商可能会收取数据通信费用)。</p>
          <p class="title">如果您已接入Wi-Fi网络:</p>
          <p>请检查您所连接的Wi-Fi热点是否已接入互联网，或该热点是否已允许您的设备访问互联网。</p>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  inject: ['reload'],
  data() {
    return {
      isShowNetworkError: false,
      isShowMore: true,
      msg:'',
      showNetworkSet: false,

    }
  },
  props: { 
  },

  computed: {
  },

  mounted() {
    //网络监听
    window.addEventListener('offline', ()=>{
      if (this.$route.name !== 'meeting') {
        this.isShowNetworkError = true
      }
    })
    window.addEventListener('online',()=>{
       this.isShowNetworkError = false
       if (this.$route.name !== 'meeting' && this.$route.name !== 'home') {
         this.reload()
       }
    })

    //展示网络异常提示 监听
    this.$eventBus.$on('show-network-error', params => {
      this.isShowNetworkError = params.show
      this.isShowMore = params.showMore || true
      this.msg = params.msg
    })
  },
  
  methods: {
    gotoNetworkSet() {
      this.showNetworkSet = true
    },
    onBackHandle() {
      this.showNetworkSet = false
    }
  }
};
</script>

<style lang="less" scoped>
.network-bad-notify {
  width: 100%;
  height: 80px;
  background:#ffeded;
  font-size: 28px;
  font-weight: 400;
  color: #FF4D4F;

  position: fixed;
  top:0;
  left:0;
  z-index:999999;

  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    margin-left: 34px;
  }
  span {
    margin-left: 16px;
  }
  .arrow {
    position: absolute;
    top: 24px;
    right: 30px;
  }
}

.network-set {
   position: fixed;
   top:0;
   left:0;
   width: 100%;
   height: 100%;
   z-index: 99999999;
   background:#fff;
   
   .text-content{
     padding-left: 75px;
     padding-right: 75px;
     margin-top: 48px;
   }
   p {
     font-size: 28px;
     line-height:40px;
     color:#999999;
   }
   .p-spacing {
     margin-top:75px;
   }
   .title {
     color:#333333;
     font-weight: 500;
     margin-bottom: 75px;
     margin-top: 100px;
   }
   .title:nth-child(1) {
      margin-top: 0;
   }
}
::v-deep .van-nav-bar .van-icon {
  color:#333;
}
::v-deep .van-hairline--bottom::after{
  border:none;
}
</style>
